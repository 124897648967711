@import "../../layout";

.stories {
    position: relative;
}

.stories-gesture-detector {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 5;
    width: 100%;
    height: 100%;
    user-select: none;
}

.stories-timer-bars {
    position: absolute;
    top: 16px;
    z-index: 10;
    width: calc(100% - 48px);
    padding: 0px 24px;
    margin: 0px auto;
    display: grid;
    grid-template-columns: repeat(var(--bars-length), minmax(0, 1fr));
    gap: 6px;
    transition: all 0.5s ease-out 0.2s;

    &.paused {
        margin-top: -52px;
    }

    .stories-timer-bar {
        width: 100%;
        height: 4px;
        border-radius: 7px;
        overflow: hidden;
        background-color: rgba(#eaeaea, 0.7);

        .stories-timer-bar-progress {
            height: 4px;
            border-radius: 7px;
            background-color: #eaeaea;
        }
    }
}

#stories-move-next-btn {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 11;

    .btn-move-next {
        @include center;
        width: 40px;
        height: 40px;
        border-radius: 13px;
        background-color: rgba(#f1f3f2, 0.5);
        color: #fff;
        border: 0px;

        img {
            transform: scaleX(-100%) translateX(-2px);
        }
    }
}