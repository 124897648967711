@import "../layout";

#story-music {
    @include column(center);
    min-height: 100vh;
    padding: 0px 24px;
    margin: 0px auto;
    background-color: #5F008B;
    color: #fff;

    #story-music-image {
        margin-top: calc(39px + min(64px, max(0px, calc(100vh - 640px))));
        margin-top: calc(39px + min(64px, max(0px, calc(100dvh - 640px))));
        width: 299px;
        height: 218px;
        opacity: 0;
        animation: appear 0.5s ease-out forwards;
    }

    #story-music-text {
        margin-top: 105px;
        font-size: 18px;
        line-height: 150%;
        opacity: 0;
        animation: appear 0.5s ease-out 0.2s forwards;
    }

    footer {
        @include center;
        position: fixed;
        bottom: 8px;
        z-index: 10;

        img {
            width: 50px;
        }
    }
}

@keyframes appear {
	from {
		opacity: 0;
		transform: translateY(15%);
	}
	to {
		opacity: 1;
		transform: translateY(0%);
	}
}