@import "./layout";

.stories {
    @include center;

    & > main {
        width: 100%;

        & > * {
            max-width: 400px;
            margin: 0 auto;
        }
    }

    .stories-timer-bars {
        max-width: 352px;
    }
}