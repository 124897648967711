@import "../layout";

#story-invite {
    @include column(center);
    min-height: 100vh;
    padding: 0px 24px;
    margin: 0px auto;
    background-color: #5F008B;
    color: #fff;

    iframe {
        position: relative;
        z-index: 10;
        width: 100%;
        height: 80px;
        margin-top: 48px;
        border: 0px;
        opacity: 0;
        animation: appear 0.5s ease-out forwards;
    }

    #story-invite-text {
        margin-top: 24px;
        line-height: 150%;
        opacity: 0;
        animation: appear 0.5s ease-out 0.2s forwards;

        @media only screen and (max-width: 340px) {
			font-size: 16px;
		}

        @media only screen and (min-width: 340px) {
			font-size: 16.5px;
		}

        @media only screen and (min-width: 350px) {
			font-size: 17px;
		}

        @media only screen and (min-width: 360px) {
			font-size: 17.5px;
		}

        @media only screen and (min-width: 370px) {
			font-size: 18px;
		}
    }

    $passify-spacing: 12px;

    #story-invite-social {
        @include row(center, center, 64px);
        position: relative;
        z-index: 10;
        margin-top: 24px;
        opacity: 0;
        animation: appear 0.5s ease-out 0.4s forwards;
    }

    #story-invite-cta {
        position: fixed;
        bottom: calc(29px + 4px + $passify-spacing);
        z-index: 10;
        margin: 0 auto;
        border-color: #d780ff;
        opacity: 0;
        animation: appear 0.5s ease-out 0.6s forwards;

        button {
            background-color: #d780ff;
        }
    }

    footer {
        @include center;
        position: fixed;
        bottom: $passify-spacing;
        z-index: 10;

        img {
            width: 50px;
        }
    }
}

@keyframes appear {
	from {
		opacity: 0;
		transform: translateY(15%);
	}
	to {
		opacity: 1;
		transform: translateY(0%);
	}
}