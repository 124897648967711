@import url('https://fonts.googleapis.com/css2?family=Oranienbaum:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "./layout";

body {
	margin: 0;
	font-family: Oranienbaum,;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body, table, button {
	font-family: Oranienbaum;
}

div {
	transition: all 0.2s ease-out;
}

img {
	border: 0;
}

a {
	color: mix($fg-color, $primary, 50%);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

$cta-bg-color: #fff;
$cta-fg-color: #000;

div.cta {
	@include center;
	border-width: 1px;
	border-style: solid;
	border-color: transparent; //$cta-bg-color;
	border-radius: 34px;
	padding: 5px;

	@media only screen and (min-width: 380px) {
		width: 315px;
	}

	@media only screen and (max-width: 380px) {
		width: 100%;
		max-width: calc(100vw - 65px) !important;
		min-width: calc(100vw - 68px) !important;
	}

	button {
		width: 100%;
		height: 66px;
		border: 0px;
		border-radius: 12px;
		font-size: 18px;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0.18px;
		color: $cta-fg-color;
		background-color: $cta-bg-color;	
		transition: all 0.2s ease-out;
	}

	&.disabled {
		border-color: $disabled-color;

		button {
			background-color: $disabled-color;
		}
	}

	&.success {
		border-color: $secondary;

		button {
			background-color: $secondary;
		}
	}
}

::-webkit-scrollbar {
	width: 3px;
}
  
::-webkit-scrollbar-track {
	background: transparent; 
}
  
::-webkit-scrollbar-thumb {
	background: rgba($primary, 0.4); 
}
  
::-webkit-scrollbar-thumb:hover {
	background: $primary; 
}