@import "../layout";

#story-lineup {
    @include column(center);
    min-height: 100vh;
    margin: 0px auto;
    background-color: #121212;
    color: #fff;

    #story-lineup-dj {
        @include center;
        position: relative;
        z-index: 1;
        width: 100%;
        height: 400px;
        margin-top: max(-64px, min(0px, calc(100vh - 663px)));
        margin-top: max(-64px, min(0px, calc(100dvh - 663px)));
        overflow: hidden;

        #story-lineup-background {
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-image: var(--dj-background-image);
            background-position-x: center;
            background-position-y: 25%;
            background-repeat: no-repeat;
            background-size: 100% auto;
            opacity: 0;
            animation: zoom 5s ease-out forwards;
        }

        #story-lineup-overlay {
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            z-index: 2;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(18, 18, 18, 0.60) 0%, rgba(18, 18, 18, 0.00) 23.89%, rgba(18, 18, 18, 0.00) 47.44%, #121212 100%);
        }

        img {
            position: absolute;
            z-index: 3;
            margin: 0px auto;
            opacity: 0;
            animation: appear 0.5s ease-out forwards;
        }
    }

    #story-lineup-description,
    #story-lineup-instagram {
        width: calc(100% - 48px);
        padding: 0px 24px;
        font-size: 18px;
    }

    #story-lineup-description {
        margin-top: 32px;
        line-height: 150%;
        opacity: 0;
        animation: appear 0.5s ease-out 0.2s forwards;
    }

    #story-lineup-instagram {
        @include row(center, null, 8px);
        position: relative;
        z-index: 10;
        margin-top: 24px;
        opacity: 0;
        animation: appear 0.5s ease-out 0.4s forwards;

        a {
            text-decoration: none;
            color: #fff;
        }

        img {
            width: 24px;
            height: 24px;
        }
    }
}

@keyframes appear {
	from {
		opacity: 0;
		transform: translateY(15%);
	}
	to {
		opacity: 1;
		transform: translateY(0%);
	}
}

@keyframes zoom {
	0% {
		opacity: 0;
		transform: scale(1);
	}
    10% {
        opacity: 1;
		transform: scale(1.025);
    }
	100% {
		opacity: 1;
		transform: scale(1.1);
	}
}